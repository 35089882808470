.download-sub-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.highcharts-exporting-group {
  display: none !important;
}

.download-sub-menu.ant-menu {
  background: transparent;
}

.download-sub-menu.ant-menu-horizontal {
  border: 0;
}
