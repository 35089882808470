.panel-color-gray {
  color: @dark-gray;
}

/* Remove the bottom border of the collapse header */
.custom-collapse .ant-collapse-content {
  border-top: none;
}

.custom-panel .ant-collapse-content-box {
  padding: 0 16px 16px 16px;
}

.ant-collapse-item {
  // margin-bottom: 11px;  /* Add a gap between the panels */
  // border-radius: 8px;   /* Round the corners of each panel */
  overflow: hidden;     /* Prevent overflow of content */
}

.ant-collapse-header {
  font-weight: 600;  /* Make the header text bold */
  font-size: 16px;   /* Adjust font size */
}

.ant-collapse-content {
  background-color: #fff;  /* Set the background color for the panel */
}

.ant-collapse-item:last-child {
  margin-bottom: 0;  /* Remove the margin for the last panel */
}