.fade-in {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.fade-in.show {
  opacity: 1;
}

// press-down effect
.badge {
  transition: transform 0.2s ease;
}

.badge:active {
  transform: scale(0.9); /* Scale down slightly to create a press-down effect */
}
// end of press-down effect