.redesignActive {
    .non-clickable {
        pointer-events: none;
        cursor: default;
    }

    .clickable-badge sup {
        transition: background-color 0.3s;
    }

    .clickable-badge:hover sup {
        background-color: @light-gray  !important;
    }

    .custom-badge-text {
        font-size: 10px;
        padding: 4px 12px;
        border-radius: 50px;
        display: inline-block;
        white-space: nowrap;
    }
}