//-------------Table---------------------
.redesignActive {
  .ant-table-thead th.ant-table-column-sort,
  .ant-table-thead > tr > th {
    .heading-06-bold-table !important;
    opacity: 1 !important;
  }

  .ant-table-tbody .ant-table-cell {
    .paragraph-02-regular;
  }

  .shadow-table .ant-table {
    box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.07);
  }

  // Just to apply in home page - transaction overview table to not affect other table
  // Need to apply to every place in the future
  .transaction-overview .ant-table-tbody .ant-table-cell {
    .heading-06-regular-table
  }

  // Just to apply in home page - transaction overview table to not affect other table
  // Need to apply to every place in the future
  .transaction-overview .ant-table-tbody > tr > td {
    padding: 10px 16px;
  }

  .nested-table .ant-table {
    margin: 0px !important;
  }

  .ant-table-expanded-row .ant-table-cell {
    padding-right: 0px !important;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border-bottom: 1px solid #dedede;
    // text-align: center;
  }

  // To change font color of line 20 of src/features/tenants/table/TenantsColumns.tsx
  .ant-table-cell > a > span {
    color: @primary;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border: 0px;
  }

  .ant-table-column-title {
    .heading-06-bold-table !important;
    opacity: 1 !important;
  }

  .ant-table-thead> tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background: none;
    width: none; // <-- add 1px to bring back the column divider between the table's header
  }

  // make the cursor become a pointer when 'rowClassName={() => 'custom-transaction-row' is used on the Table component. Currently used in TransactionTable.tsx. Might be used in other tables later.
    .ant-table-tbody > tr.custom-transaction-row {
      cursor: pointer;
      .ant-table-cell {
        vertical-align: top;
      }

      .ant-table-cell:last-child {
        vertical-align: middle;
      }

    }

  // !IMPORTANT: override the default antd style
  /* make a transaction row become light colour of the primary colour when its panel is open and the row is selected by adding a custom class name called 'selected-table-row'. Currently used in TransactionTable.tsx */
  .ant-table-tbody >tr.selected-table-row > td {
    background: @grayBackground;
  }

  // Only applied to tenants table for now but may apply to every table later
  .tenants-table .ant-table-expanded-row {
    display: none;
  }

  .expandable-table .ant-table-expanded-row {
    display: none;
  }

  .custom-row-collapse > td > * {
    max-height: 20px;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
  .custom-row-collapse-tenant-activity-tracking > td > * {
    max-height: 40px;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }

  .custom-row-collapse-pricing-plan > td > * {
    max-height: 40px;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }

  .user-driver {
    max-width: 10vw ;
  }

  .custom-row-collapse-users-page > td > * {
    max-height: 63px;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }

  .ant-table-cell {
    font-family: @table-cell-font-family !important;
    * { // This targets all child elements within .ant-table-cell
      font-family: inherit !important;
    }
  }

  // for new chargers page //
  // customize the select all checkbox
  .chargers-table-wrapper {
    position: relative;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  }

  .custom-select-all-checkbox {
    position: absolute;
    top: 1.6rem;
    left: 0.8rem;
    z-index: 10;
  }

  // for new chargers page to customize the selected row color
  .custom-table-selected-row-color .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: @tableSelectedRowBg !important;
  }

  .custom-table-selected-row-color .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background-color: @tableSelectedRowHoverBg !important;
  }

  .custom-table-selected-row-color .ant-table-tbody > tr:not(.ant-table-row-selected):not(.exclude-from-style) > td {
    background-color: @tableNonSelectedRowBg; // Background color for non-selected rows
  }

  .custom-table-selected-row-color .ant-table-tbody > tr:not(.ant-table-row-selected):not(.exclude-from-style):hover > td {
    background-color: @cultered; // Background color for non-selected rows when hovered
  }
  // end of the new chargers page

}
//-------------Table---------------------