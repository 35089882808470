// -------------App Header----------------------------
.lng-option img.right-arrow {
  display: none !important;
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
  border-radius: 5px;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
  border: 1px solid #c1c3d0;
}

.ant-menu.ant-menu-sub.ant-menu-vertical li {
  background-color: @white;
}

.ant-menu.ant-menu-sub.ant-menu-vertical .ant-menu-item-disabled .ant-menu-title-content {
  color: @primary;
}

.ant-menu.ant-menu-sub.ant-menu-vertical .ant-menu-item-active {
  color: @black;
  background-color: #fafafa;
}

.ant-menu.ant-menu-sub .ant-menu-title-content {
  font-family: 'Akkurat LL Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: @soft-black;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
// -------------App Header----------------------------
