//-------------Tabs---------------------
.redesignActive {
  .ant-tabs-tab-btn {
    color: @soft-black;
    text-shadow: 0 0 0.25px currentcolor;
    background-color: #fff;
    padding: 7px 13px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    font-family: 'AkkuratLLWeb-Regular';
  }

  .ant-tabs-tab-btn:hover {
    background-color: #f1f1f1;
    border-color: @soft-black;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
    background-color: @soft-black;
  }

  .ant-tabs-tab {
    margin: 0;
    padding: 3px;
  }

  .ant-tabs-nav-list {
    border-top-left-radius: 41px;
    border-bottom-left-radius: 41px;
    border-top-right-radius: 41px;
    border-bottom-right-radius: 41px;
    background-color: @grayBackground;
    border: 1px solid @light-gray;
  }

  .ant-tabs-tab-btn {
    border-top-left-radius: 41px;
    border-bottom-left-radius: 41px;
    border-top-right-radius: 41px;
    border-bottom-right-radius: 41px;
    background-color: #fafafa;
    padding: 7.5px 20px;
  }

  .ant-tabs-tab-btn > .regular-cap {
    font-family: @light-font-family;
  }

  .ant-tabs-tab:not(.ant-tabs-tab-active) .ant-tabs-tab-btn:hover {
    background-color: #f1f1f1;
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    width: 0 !important;
  }

  .operation-overview-tabs {
    height: 100%;
  }

  .operation-overview-tabs .ant-tabs-nav {
    margin: 20px 0;
  }

  .operation-overview-tabs .ant-tabs-content-holder {
    margin-top: 0;
    height: 100%;
    align-items: center;
    display: flex;
  }

  .ant-tabs-nav-operations .ant-tabs-nav-more {
    display: none;
  }

  .right-padding-tab .ant-tabs-nav-wrap {
    margin-right: 20px;
  }

  .ant-tabs-extra-content {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .ant-tabs-extra-content p {
    margin: 0
  }

  .ant-tabs-nav-wrap {
    flex: 0 1 auto !important;
  }

  .center-tabs .ant-tabs-extra-content {
    flex: 0 !important; 
  }

  .center-tabs .ant-tabs-nav-wrap {
    flex: 1 !important; 
    justify-content: center !important;
  }
}
//-------------Tabs---------------------
