.custom-dropdown .ant-dropdown-menu {
  border: 1px solid @light-gray !important;
  border-radius: 5px;
}


.custom-dropdown .ant-dropdown-menu-item {
  padding: 8px 16px;
}

.custom-dropdown {
  svg {
    margin-right: 8px;
  }
}
