.redesignActive {
  .ant-pagination-item-active {
    background-color: @button-primary;
    border-radius: 20px;
  }

  .ant-pagination-item {
    height: 36px !important;
    width: 36px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 1px solid @light-gray !important;
    margin-right: 8px !important; //margin right for number element
  }

  .ant-pagination-item.ant-pagination-item-active {
    border-color: transparent !important;
  }

  .ant-pagination-next {
    margin-left: 0px !important; //remove margin left for next button
  }

  .ant-pagination-item a {
    line-height: unset !important;
    .paragraph-01-regular;
    color: @soft-black;
    font-weight: 700;
  }

  .ant-pagination-item:hover a {
    color: @soft-black;
  }

  .ant-pagination-item-active:hover a {
    color: black;
  }

  .ant-pagination-item-active a {
    color: black;
  }

  .ant-pagination-disabled .ant-pagination-item-link {
    background-color: #fafafa;
    border: 1px #f1f1f1 solid;
  }

  .ant-pagination-item-link span.anticon.anticon-right {
    display: block;
  }

  .ant-pagination-prev a,
  .ant-pagination-next a {
    color: @soft-black;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    position: relative;
    height: 36px !important;
    width: 36px;
    border-radius: 20px;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 1px solid @light-gray;
  }

  .ant-pagination-prev::after,
  .ant-pagination-next::after {
    @svg-color: escape(@soft-black);
    margin: 8px 1px 0 0;
    content: url("data:image/svg+xml, %3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 13L7 9L11 5' stroke='@{svg-color}' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E ");
  }

  .ant-pagination-item:hover,
  .ant-pagination-prev:hover,
  .ant-pagination-next:hover {
    @outer-border-color: fade(@button-primary, 10); //10% of primary
    border-color:@button-primary !important;
    box-shadow:
        0 0 0 4px @outer-border-color !important; //outer border
  }

  .ant-pagination-item:active,
  .ant-pagination-prev:active,
  .ant-pagination-next:active {
    background-color:@button-primary !important;
    border-color: @button-primary !important;
  }

  .ant-pagination-prev.ant-pagination-disabled,
  .ant-pagination-next.ant-pagination-disabled {
    background-color: @grayBackground !important;
    border-color: @cultered !important;
    box-shadow: none !important;
  }

  .ant-pagination-prev.ant-pagination-disabled::after,
  .ant-pagination-next.ant-pagination-disabled::after {
    @svg-color: fade(@soft-black, 10);
    content: url("data:image/svg+xml, %3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 13L7 9L11 5' stroke='@{svg-color}' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E ");
  }

  .ant-pagination-next::after {
    margin: 0 0 8px 1px;
    transform: rotate(180deg);
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: @soft-black;
    opacity: 1 !important;
  }

  .anticon.anticon-double-left.ant-pagination-item-link-icon,
  .anticon.anticon-double-right.ant-pagination-item-link-icon,
  button.ant-pagination-item-link {
    display: none;
  }
}