.redesignActive {
  .pointer {
    cursor: pointer;
  }

  .pointer-not-allowed {
    cursor: not-allowed;
  }

  .require-underline {
    text-decoration: underline;
  }

  .underline {
    text-decoration: none;
  }

  .underline:hover {
    text-decoration: underline;
  }
  .disable-link, .disable-link:hover {
    text-decoration: none;
    cursor: default;
    pointer-events: none;
  }

  .primary-color-text {
    color: @primary;
  }
}