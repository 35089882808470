@import './select-inputs.less';

//-------------Location Selector---------------------
.redesignActive {
  .location-selector .ant-select-selection-overflow {
    margin-left: 20px;
  }

  // default the ant-select-placeholder to the same as the ant-select-selection-item-content
  .ant-select-selection-placeholder {
    font-family: 'AkkuratLLWeb-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .ant-cascader-menu-item-content {
    font-family: 'AkkuratLLWeb-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    align-items: center;
    color: @soft-black;
  }

  .ant-cascader-checkbox-inner {
    border-radius: 5px !important;
  }

  .tenant-page .ant-cascader-menu {
    min-width: 140px;
    border-radius: 5px;
  }

  .ant-cascader-menu {
    min-width: 240px;
    // min-height: 280px;
    border-radius: 5px;
  }

  .ant-cascader-checkbox-checked::after {
    border: 0;
  }

  .ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
    background-color: @button-primary;
    border-color: @button-primary;
  }

  .ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
    border-color: @black;
    border-right-width: 2px;
    border-bottom-width: 2px;
  }

  .ant-cascader-menu-item:hover,
  .ant-cascader-menu .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
  .redesignActive .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    background: @active-selection;
  }

  .ant-cascader-menu-item:hover .ant-cascader-menu-item-content {
    color: @soft-black;
  }

  li.ant-cascader-menu-item.ant-cascader-menu-item-expand.ant-cascader-menu-item-active[aria-checked='false']
    .ant-cascader-checkbox-indeterminate
    .ant-cascader-checkbox-inner::after {
    background-color: @button-primary;
    position: absolute;
    display: table;
    border: 2px solid @black;
    border-top: 0;
    border-left: 0;
    top: 50%;
    left: 21.5%;
    width: 5.71428571px;
    height: 9.14285714px;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }

  .anticon.anticon-right {
    display: none;
  }

  .ant-cascader-menu-item-expand-icon::after {
    content: url("data:image/svg+xml, %3Csvg width='6' height='9' viewBox='0 0 6 9' fill='none' xmlns='http://www.w3.org/2000/svg'  %3E%3Cpath d='M5.657 4.24299L1.414 8.48599L0 7.07099L2.829 4.24299L0 1.41499L1.414 -7.62939e-06L5.657 4.24299Z' fill='%2309121F' /%3E%3C/svg%3E");
    transform: rotate(90deg);
    display: inline-block;
    transition: all 500ms ease;
  }

  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
    background-color: @grayBackground;
  }

  li.ant-cascader-menu-item.ant-cascader-menu-item-expand.ant-cascader-menu-item-active[aria-checked='false']
    .ant-cascader-checkbox-indeterminate
    .ant-cascader-checkbox-inner {
    background-color: @button-primary;
    border-color: @button-primary;
  }

  li.ant-cascader-menu-item[aria-checked='false']:hover .ant-cascader-checkbox-inner {
    border-color: #c1c3d0;
    background-color: #f1f1f1;
  }

  .ant-select-multiple span.ant-select-selection-item {
    background-color: @active-selection;
    border-radius: 17px;
  }

  .ant-select-multiple span.ant-select-selection-item .ant-select-selection-item-content {
    font-size: 11px;
  }

  .location-selector.single .ant-select-clear {
    opacity: 0 !important;
  }

  .location-selector .ant-select-clear {
    opacity: 1 !important;
  }

  .location-selector .ant-select-selection-item-remove {
    display: flex;
    margin-top: 7px;
    margin-right: 7px;
  }
}
//-------------Location Selector---------------------